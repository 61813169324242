import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getAllModules(filters, sort) {
    return Api().get(`/v1/module?organization_id=${organization_id()}`);
  },
};
