<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard 
    Author:  
    Author URL:  
========================================================================================== -->
<template>
  <div>
    <!-- ADD DEPARTMENT FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Access Control">
          <div>
            <div class="row mb-2">
              <div class="col-6 mb-2">
                <div class>
                  <h6>Role*</h6>
                  <!-- <vs-select
                  v-validate="'required'"
                  v-model="form.role_id"
                  placeholder="Select Role"
                  name="Select Role"
                  class="select-large w-full"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.role_id"
                    :text="item.role_name"
                    v-for="(item,index) in roles"
                  />
                  </vs-select>-->
                  <multiselect
                    v-model="role_value"
                    track-by="role_id"
                    label="role_name"
                    @input="({role_id})=> this.form.role_id = role_id"
                    :options="roles"
                    open-direction="bottom"
                    name="Role Name"
                    placeholder="Select Role"
                    :searchable="false"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.role_name }}</template>
                  </multiselect>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Role Name')"
                  >{{ errors.first('Role Name') }}</span>
                </div>
              </div>
              <div class="col-6 mb-2">
                <h6>Project*</h6>
                <!-- <vs-select
                v-validate="'required'"
                v-model="project_value"
                track-by="project_id"
                label="project_name"
                placeholder="Select Project"
                @input="({project_id}) => this.form.project_id = project_id"
                :options="projects"
                name="Project Name"
                :searchable="false"
                :allow-empty="false"
              >
                <vs-select-item
                  :key="index"
                  :value="item.project_id"
                  :text="item.project_name"
                  v-for="(item,index) in projects"
                />
                </vs-select>-->
                <multiselect
                  v-model="project_value"
                  track-by="project_id"
                  label="project_name"
                  @input="({project_id})=> this.form.project_id = project_id"
                  :options="projects"
                  name="Project Name"
                  open-direction="bottom"
                  placeholder="Select Project"
                  :searchable="false"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ option.project_name }}</template>
                </multiselect>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                >{{ errors.first('Project Name') }}</span>
              </div>
              <!-- ************* End -->
            </div>

            <vs-button v-on:click="getPermissions()" class="mr-3 mb-5">Submit</vs-button>

            <!-- </div> -->
            <!-- <div class="vx-row">
            <div class="vx-col md:w-1/6" v-for="(methods, m) in 6" :key="m">1</div>
            </div>-->

            <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th
                    v-for="(method, methodIndex) in methods"
                    :key="methodIndex"
                    scope="col"
                  >{{ method.method_name }}</th>
                  <!-- <th scope="col">Add</th>
                <th scope="col">Update</th>
                  <th scope="col">Delete</th>-->
                </tr>
              </thead>
              <tbody>
                <tr :key="index" text="Select Role" v-for="(moduleItem,index) in modules">
                  <th scope="row">{{ moduleItem.module_name }}</th>
                  <td>
                    <!-- {{moduleItem}} -->
                    <vs-switch
                      true-value="1"
                      false-value="2"
                      class="mx-auto"
                      v-on:change.prevent="onChangePermission(moduleItem, moduleItem.methods.GET)"
                      v-model="moduleItem.methods.GET.value"
                    />
                    <!-- <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" />
                    <label class="custom-control-label" for="customCheck1"></label>
                    </div>-->
                  </td>
                  <td>
                    <vs-switch
                      true-value="1"
                      false-value="2"
                      class="mx-auto"
                      v-on:change="onChangePermission(moduleItem, moduleItem.methods.POST)"
                      v-model="moduleItem.methods.POST.value"
                    />

                    <!-- <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck2" />
                    <label class="custom-control-label" for="customCheck2"></label>
                    </div>-->
                  </td>
                  <td>
                    <vs-switch
                      true-value="1"
                      false-value="2"
                      class="mx-auto"
                      v-on:change="onChangePermission(moduleItem, moduleItem.methods.PUT)"
                      v-model="moduleItem.methods.PUT.value"
                    />

                    <!-- <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck3" />
                    <label class="custom-control-label" for="customCheck3"></label>
                    </div>-->
                  </td>
                  <td>
                    <vs-switch
                      true-value="1"
                      false-value="2"
                      class="mx-auto"
                      v-on:change="onChangePermission(moduleItem, moduleItem.methods.DELETE)"
                      v-model="moduleItem.methods.DELETE.value"
                    />

                    <!-- <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck4" />
                    <label class="custom-control-label" for="customCheck4"></label>
                    </div>-->
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="text-center">
              <!-- <vs-button class="mr-3 mb-2">Save</vs-button> -->
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import moduleService from "@/services/moduleService.js";
import methodService from "@/services/methodService.js";
import projectService from "@/services/projectService.js";
import permissionService from "@/services/permissionService.js";
import userRolesService from "@/services/userRolesService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      roles: [],
      projects: [],
      form: {
        project_id: "",
        role_id: "",
        module_id: "",
        method_id: "",
        permission_active: "1",
      },
      SelectedRole: "Select Role",
      modules: [],
      role_value: [],
      project_value: [],
      permissions: [],
      methods: [],
      submitStatus: false,
      User: "Select User",
      // form: {
      //   department_name: "",
      //   description: "",
      //   role_id: "",
      //   department_active: "2"
      // },
      companies: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {
    console.log("object");
    // this.getPermissions();
    this.getRoles();
    this.getProjects();
    this.getAllModules();
    this.getAllMethods();
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onSelectProject: function () {
      console.log("object1");
    },
    onChangePermission: function (item, value) {
      console.log("item :", item);
      console.log("value :", value);
      let payload = {
        project_id: this.form.project_id,
        role_id: this.form.role_id,
        module_id: item.module_id,
        method_id: value.id,
        permission_active: value.value ? "1" : "2",
      };

      permissionService.addPermission(payload).then((result) => {
        this.$vs.notify({
          title: "Updated!",
          text: "updated",
          iconPack: "feather",
          icon: "check_box",
          color: "success",
        });
      });
    },
    getPermissions: function () {
      this.permissions = [];
      this.getAllModules();
      let payload = {
        project_id: this.form.project_id,
        role_id: this.form.role_id,
      };
      permissionService
        .getPermissionByProjectRoleId(payload)
        .then((result) => {
          const { data } = result;
          if (data && data.Status == true) {
            this.permissions = data.data;
            this.permissions.map((y) => {
              this.modules = this.modules.map((x) => {
                Object.keys(x.methods).map((z) => {
                  if (
                    x.module_name == y.module_name &&
                    y.method_name == z &&
                    y.permission_active == 1
                  ) {
                    x.methods[z].value = true;
                    x.methods[z]["method_id"] = y.method_id;
                  }
                });
                return x;
              });
            });
          }
        })
        .catch((err) => {});
    },
    getRoles: function () {
      userRolesService
        .getAllUserRoles()
        .then((result) => {
          const { data } = result;
          if (data && data.Status == true) {
            this.roles = data.data;
          }
        })
        .catch((err) => {});
    },
    getProjects: function () {
      projectService
        .getAllProjects()
        .then((result) => {
          const { data } = result;
          if (data && data.Status == true) {
            this.projects = data.data;
            // this.projects.push({
            //   project_id: "111",
            //   project_name: "Test12121"
            // });
          }
        })
        .catch((err) => {});
    },
    getAllModules: function () {
      this.modules = [];
      moduleService
        .getAllModules()
        .then((result) => {
          let { data } = result;
          if (data && data.Status == true) {
            // this.modules = data.data;
            this.modules = data.data.map((x) => {
              x.methods = {
                GET: {
                  value: false,
                  id: 5,
                },
                PUT: {
                  value: false,
                  id: 7,
                },
                POST: {
                  value: false,
                  id: 6,
                },
                DELETE: {
                  value: false,
                  id: 8,
                },
              };
              return x;
            });

            // thiswmodules =
          }
        })
        .catch((err) => {});
    },
    //getAllMethods
    getAllMethods: function () {
      this.methods = [];
      methodService
        .getAllMethods()
        .then((result) => {
          const { data } = result;
          if (data && data.Status == true) {
            this.methods = data.data;
          }
        })
        .catch((err) => {});
    },
    onSelectRole: function (e, item) {
      console.log("item :", item);
    },
  },
};
</script>

<style scoped>
.custom-control {
  height: 33px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #7367f0;
  background-color: #7367f0;
}

.custom-control-label::before {
  width: 25px;
  height: 25px;
}

.custom-control-label::after {
  width: 25px;
  height: 25px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(115, 103, 240, 0.4);
}

.table-bordered {
  border: none !important;
}

table {
  border-collapse: unset !important;
}

table thead tr th {
  background-color: #938af3 !important;
  color: white;
}

table th,
.table td {
  vertical-align: middle !important;
  border-bottom-width: 1px !important;
}
</style>